<template>
  <page-container title="基础功能管理" breadcrumb="">
    <div class="left-page" >
      <div class="box-container-inner" style="height: 100%; overflow: auto;">
        <div style="display: flex">
          <h2>监控点</h2>
          <a-radio-group v-model="type" default-value="four" button-style="solid" style="margin-left: auto">
            <a-radio-button value="one">
              一
            </a-radio-button>
            <a-radio-button value="four">
              四
            </a-radio-button>
<!--            <a-radio-button value="nine">-->
<!--              六-->
<!--            </a-radio-button>-->
          </a-radio-group>
        </div>
        <div style="display: flex;margin-top: 5px">
          <a-input v-model="devicename"></a-input>
          <a-button type="primary" v-if="show" @click="search">搜索</a-button>
          <a-button type="primary" v-if="!show" @click="returnPage">返回</a-button>
        </div>
        <a-tree
            v-if="show"
            :selected-keys.sync="selectedKeys"
            :replace-fields="{children:'children', title:'userdepname', key:'userdepid'}"
            :tree-data="userDeptTree"
            :load-data="onLoadData"
            @select="selectDept"
        >
        </a-tree>
        <a-table :columns="tableColumns" :data-source="tableData" :customRow="rowClick" v-if="!show" row-key="userdepid" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;">
        </a-table>
      </div>
    </div>
    <div class="right-page">
      <div class="video" v-if="type=='one'">
        <div style="border:1px solid white;width: 100%;height: 100%">
          <h3 style="margin-top: 5px;margin-left: 10px;position:fixed; z-Index:10;width: 62%;color: white">{{name}}<img src="~@/assets/image/yclj.png" style="position:relative; z-Index:99999;width: 30px;" @click="remoteControl(name)"><img src="~@/assets/image/close.png" style="position:relative; z-Index:99999;width: 30px;float: right" @click="closeOne(0)"></h3>
          <video
              :id="'video'"
              controls = "true"
              width="100%"
              height="100%"
              display="flex"
              style="margin-left: 0px;border:3px solid white;">
          </video>
        </div>
      </div>
      <div class="video" v-if="type=='four'">
        <div style="border:1px solid white;width: 50%;height: 50%">
          <h3 style="margin-top: 5px;margin-left: 10px;position:fixed; z-Index:10;width: 32%;color: white">{{name_0}}<img src="~@/assets/image/yclj.png" style="position:relative; z-Index:99999;width: 30px;" @click="remoteControl(name_0)"><img src="~@/assets/image/close.png" style="position:relative; z-Index:99999;width: 30px;float: right" @click="close(0)"></h3>
          <video
              :id="'video_0'"
              controls = "true"
              width="100%"
              height="100%"
              display="flex"
              style="margin-left: 0px;border:3px solid white;">
          </video>
        </div>
        <div style="border:1px solid white;width: 50%;height: 50%">
          <h3 style="margin-top: 5px;margin-left: 10px;position:fixed; z-Index:10;width: 32%;color: white">{{name_1}}<img src="~@/assets/image/yclj.png" style="position:relative; z-Index:99999;width: 30px;" @click="remoteControl(name_1)"><img src="~@/assets/image/close.png" style="position:relative; z-Index:99999;width: 30px;float: right" @click="close(1)"></h3>
          <video
              :id="'video_1'"
              controls = "true"
              width="100%"
              height="100%"
              display="flex"
              style="margin-left: 0px;border:3px solid white;">
          </video>
        </div>
        <div style="border:1px solid white;width: 50%;height: 50%">
          <h3 style="margin-top: 5px;margin-left: 10px;position:fixed; z-Index:10;width: 32%;color: white">{{name_2}}<img src="~@/assets/image/yclj.png" style="position:relative; z-Index:99999;width: 30px;" @click="remoteControl(name_2)"><img src="~@/assets/image/close.png" style="position:relative; z-Index:99999;width: 30px;float: right" @click="close(2)"></h3>
          <video
              :id="'video_2'"
              controls = "true"
              width="100%"
              height="100%"
              display="flex"
              style="margin-left: 0px;border:3px solid white;">
          </video>
        </div>
        <div style="border:1px solid white;width: 50%;height: 50%">
          <h3 style="margin-top: 5px;margin-left: 10px;position:fixed; z-Index:10;width: 32%;color: white">{{name_3}}<img src="~@/assets/image/yclj.png" style="position:relative; z-Index:99999;width: 30px;" @click="remoteControl(name_3)"><img src="~@/assets/image/close.png" style="position:relative; z-Index:99999;width: 30px;float: right" @click="close(3)"></h3>
          <video
              :id="'video_3'"
              controls = "true"
              width="100%"
              height="100%"
              display="flex"
              style="margin-left: 0px;border:3px solid white;">
          </video>
        </div>
      </div>
      <div class="video" v-if="type=='nine'">
        <div style="border:1px solid white;width: 33.3%;height: 50%">
          <h3 style="margin-top: 5px;margin-left: 10px;position:fixed; z-Index:10;width: 21%;color: white">{{name_0}}<img src="~@/assets/image/yclj.png" style="position:relative; z-Index:99999;width: 30px;" @click="remoteControl(name_0)"><img src="~@/assets/image/close.png" style="position:relative; z-Index:99999;width: 30px;float: right" @click="close(0)"></h3>
          <video
              :id="'video_0'"
              controls = "true"
              width="100%"
              height="100%"
              display="flex"
              style="margin-left: 0px;border:3px solid white;">
          </video>
        </div>
        <div style="border:1px solid white;width: 33.3%;height: 50%">
          <h3 style="margin-top: 5px;margin-left: 10px;position:fixed; z-Index:10;width: 21%;color: white">{{name_1}}<img src="~@/assets/image/yclj.png" style="position:relative; z-Index:99999;width: 30px;" @click="remoteControl(name_1)"><img src="~@/assets/image/close.png" style="position:relative; z-Index:99999;width: 30px;float: right" @click="close(1)"></h3>
          <video
              :id="'video_1'"
              controls = "true"
              width="100%"
              height="100%"
              display="flex"
              style="margin-left: 0px;border:3px solid white;">
          </video>
        </div>
        <div style="border:1px solid white;width: 33.3%;height: 50%">
          <h3 style="margin-top: 5px;margin-left: 10px;position:fixed; z-Index:10;width: 21%;color: white">{{name_2}}<img src="~@/assets/image/yclj.png" style="position:relative; z-Index:99999;width: 30px;" @click="remoteControl(name_2)"><img src="~@/assets/image/close.png" style="position:relative; z-Index:99999;width: 30px;float: right" @click="close(2)"></h3>
          <video
              :id="'video_2'"
              controls = "true"
              width="100%"
              height="100%"
              display="flex"
              style="margin-left: 0px;border:3px solid white;">
          </video>
        </div>
        <div style="border:1px solid white;width: 33.3%;height: 50%">
          <h3 style="margin-top: 5px;margin-left: 10px;position:fixed; z-Index:10;width: 21%;color: white">{{name_3}}<img src="~@/assets/image/yclj.png" style="position:relative; z-Index:99999;width: 30px;" @click="remoteControl(name_3)"><img src="~@/assets/image/close.png" style="position:relative; z-Index:99999;width: 30px;float: right" @click="close(3)"></h3>
          <video
              :id="'video_3'"
              controls = "true"
              width="100%"
              height="100%"
              display="flex"
              style="margin-left: 0px;border:3px solid white;">
          </video>
        </div>
        <div style="border:1px solid white;width: 33.3%;height: 50%">
          <h3 style="margin-top: 5px;margin-left: 10px;position:fixed; z-Index:10;width: 21%;color: white">{{name_4}}<img src="~@/assets/image/yclj.png" style="position:relative; z-Index:99999;width: 30px;" @click="remoteControl(name_4)"><img src="~@/assets/image/close.png" style="position:relative; z-Index:99999;width: 30px;float: right" @click="close(3)"></h3>
          <video
              :id="'video_4'"
              controls = "true"
              width="100%"
              height="100%"
              display="flex"
              style="margin-left: 0px;border:3px solid white;">
          </video>
        </div>
        <div style="border:1px solid white;width: 33.3%;height: 50%">
          <h3 style="margin-top: 5px;margin-left: 10px;position:fixed; z-Index:10;width: 21%;color: white">{{name_5}}<img src="~@/assets/image/yclj.png" style="position:relative; z-Index:99999;width: 30px;" @click="remoteControl(name_5)"><img src="~@/assets/image/close.png" style="position:relative; z-Index:99999;width: 30px;float: right" @click="close(3)"></h3>
          <video
              :id="'video_5'"
              controls = "true"
              width="100%"
              height="100%"
              display="flex"
              style="margin-left: 0px;border:3px solid white;">
          </video>
        </div>
      </div>
    </div>
    <a-modal v-model="modalVisible" :width="520" title="监控点信息" :footer="null" :zIndex="999999" :dialogStyle="dialogStyle">
      <div style="width: 500px">
        <a-form-model ref="userDeptForm" layout="inline" :model="formDatas" class="form-in-twocolumns" :label-col="{span:10}" :wrapper-col="{span:14}">
          <a-form-model-item label="监控点ID" prop="monitorid">
            <span>{{formDatas.monitorid}}</span>
          </a-form-model-item>
          <a-form-model-item label="详细地址" prop="address">
            <span>{{formDatas.address}}</span>
          </a-form-model-item>
          <a-form-model-item label="单位电话" prop="telphone" :max-length="12">
            <span>{{formDatas.telphone}}</span>
          </a-form-model-item>
          <a-form-model-item label="邮编" prop="mailcode">
            <span>{{formDatas.mailcode}}</span>
          </a-form-model-item>
          <!-- 使用单位 -->
          <a-form-model-item label="单位经理" prop="depmanager">
            <span>{{formDatas.depmanager}}</span>
          </a-form-model-item>
          <a-form-model-item label="单位经理电话" prop="depmanagerphone">
            <span>{{formDatas.depmanagerphone}}</span>
          </a-form-model-item>
          <a-form-model-item label="大区经理" prop="regionmanager">
            <span>{{formDatas.regionmanager}}</span>
          </a-form-model-item>
          <a-form-model-item label="大区经理电话" prop="regionmanagerphone">
            <span>{{formDatas.regionmanagerphone}}</span>
          </a-form-model-item>
          <a-form-model-item label="城市经理" prop="citymanager">
            <span>{{formDatas.citymanager}}</span>
          </a-form-model-item>
          <a-form-model-item label="城市经理电话" prop="citymanagerphone">
            <span>{{formDatas.citymanagerphone}}</span>
          </a-form-model-item>
          <a-form-model-item label="片区经理" prop="areamanager">
            <span>{{formDatas.areamanager}}</span>
          </a-form-model-item>
          <a-form-model-item label="片区经理电话" prop="areamanagerphone">
            <span>{{formDatas.areamanagerphone}}</span>
          </a-form-model-item>
          <a-form-model-item label="项目经理" prop="itemsmanager">
            <span>{{formDatas.itemsmanager}}</span>
          </a-form-model-item>
          <a-form-model-item label="项目经理电话" prop="itemsmanagerphone">
            <span>{{formDatas.itemsmanagerphone}}</span>
          </a-form-model-item>
          <a-form-model-item label="工程经理" prop="projectmanager">
            <span>{{formDatas.projectmanager}}</span>
          </a-form-model-item>
          <a-form-model-item label="工程经理电话" prop="projectmanagerphone">
            <span>{{formDatas.projectmanagerphone}}</span>
          </a-form-model-item>
          <a-form-model-item label="值班人员1" prop="operator1">
            <span>{{formDatas.operator1}}</span>
          </a-form-model-item>
          <a-form-model-item label="值班人员1电话" prop="operator1phone">
            <span>{{formDatas.operator1phone}}</span>
          </a-form-model-item>
          <a-form-model-item label="值班人员2" prop="operator2">
            <span>{{formDatas.operator2}}</span>
          </a-form-model-item>
          <a-form-model-item label="值班人员2电话" prop="operator2phone">
            <span>{{formDatas.operator2phone}}</span>
          </a-form-model-item>
          <a-form-model-item label="值班人员3" prop="operator3">
            <span>{{formDatas.operator3}}</span>
          </a-form-model-item>
          <a-form-model-item label="值班人员3电话" prop="operator3phone">
            <span>{{formDatas.operator3phone}}</span>
          </a-form-model-item>
          <a-form-model-item label="值班人员4" prop="operator4">
            <span>{{formDatas.operator4}}</span>
          </a-form-model-item>
          <a-form-model-item label="值班人员4电话" prop="operator4phone">
            <span>{{formDatas.operator4phone}}</span>
          </a-form-model-item>
        </a-form-model>
      </div>
    </a-modal>
  </page-container>
</template>
<script>
import flvjs from "flv.js/dist/flv.js"
import {playClients} from "A/monitoring";
import {getChildTreeListByParentId, getUserdepListByCondition} from "A/jcgn";
import {getDictByDicType, setPlayerParams} from "A/xtpz";
import {mapState} from "vuex";
export default {
  name: "preview",
  data () {
    return {
      oneWindows:[
        {
          name:'',
          num:'',
          id:'',
          src:'',
          flag:false,
        },
      ],
      fourWindows:[
        {
          name:'',
          num:'',
          id:'',
          src:'',
          flag:false,
        },
        {
          name:'',
          num:'',
          id:'',
          src:'',
          flag:false,
        },
        {
          name:'',
          num:'',
          id:'',
          src:'',
          flag:false,
        },
        {
          name:'',
          num:'',
          id:'',
          src:'',
          flag:false,
        }
      ],
      nineWindows:[
        {
          name:'',
          num:'',
          id:'',
          src:'',
          flag:false,
        },
        {
          name:'',
          num:'',
          id:'',
          src:'',
          flag:false,
        },
        {
          name:'',
          num:'',
          id:'',
          src:'',
          flag:false,
        },
        {
          name:'',
          num:'',
          id:'',
          src:'',
          flag:false,
        },
        {
          name:'',
          num:'',
          id:'',
          src:'',
          flag:false,
        },
        {
          name:'',
          num:'',
          id:'',
          src:'',
          flag:false,
        }
      ],
      name:'',
      name_0:'',
      name_1:'',
      name_2:'',
      name_3:'',
      name_4:'',
      name_5:'',
      flvPlayer:null,
      flvPlayer0:null,
      flvPlayer1:null,
      flvPlayer2:null,
      flvPlayer3:null,
      flvPlayer4:null,
      flvPlayer5:null,
      videoElement:null,
      videoElement0:null,
      videoElement1:null,
      videoElement2:null,
      videoElement3:null,
      videoElement4:null,
      videoElement5:null,

      userDeptTree: [],
      selectedKeys: [],
      show:true,
      type:'four',
      videoUrl:'',
      count:0,
      monitorid:'',
      currentnum:0,
      devicename:'',
      monitorname:'',
      time:null,
      devlists:[],
      modalVisible:false,
      dialogStyle:{
        right:"36%",
      },
      userdepname:'',
      tableData: [],
      tableLoading: false,
      tableColumns: [
        {
          title: '监控点名称',
          dataIndex: 'userdepname',
          key: 'userdepname',
          ellipsis: true,
        },
      ],
      formDatas: {
        monitorid:'',
        remotecode:'',
        userdepid: '',
        parentid: '',
        userdepname: '',
        deptype: '',
        levelid: '',
        abbreviation: '',
        sequenceno: 1,
        areaList: [],
        areacode: '',
        address: '',
        telphone: '',
        mailcode: '',
        depmanager: '',
        depmanagerphone: '',
        regionmanager: '',
        regionmanagerphone: '',
        citymanager: '',
        citymanagerphone: '',
        areamanager: '',
        areamanagerphone: '',
        itemsmanager: '',
        itemsmanagerphone: '',
        projectmanager: '',
        projectmanagerphone: '',
        legalperson: '',
        legalphone: '',
        legalid: '',
        coordinatex: '',
        coordinatey: '',
        certificatenum: '',
        certificatepic: '',
        depnum: '',
        deppic: '',
        businessnum: '',
        businesspic: '',
        licensenum: '',
        licensepic: '',
        propertyid: '',
        userdepdesc: '',
        createtime:'',
        updatetime:'',
        operator1:'',
        operator1phone:'',
        operator2:'',
        operator2phone:'',
        operator3:'',
        operator3phone:'',
        operator4:'',
        operator4phone:'',
      },
    };
  },
  destroyed() {
    this.initialize()
  },
  watch:{
    'type':function (){
      this.initialize();
    },
  },
  mounted() {
    // let that = this;
    // setTimeout(function () {
    //   that.initPlayer();
    // }, 1000);
  },
  created() {
    this.initialize()
    this.init()
  },
  computed: {
    player() {
      return this.$refs.videoPlayer.player
    },
    ...mapState({
      userInfo: state => state.userInfo
    }),
  },
  methods: {
    init(){
      this.getDeptTypes();
      this.getDeptTree();
    },
    onLoadData(node) {
      return new Promise(resolve => {
        if (node.dataRef.children) {
          resolve();
          return;
        }
        let params = {
          parentid: node.dataRef.userdepid
        };
        this.showLoading();
        getChildTreeListByParentId(params).then(res => {
          this.hideLoading();
          if(res && res.returncode == '0') {
            node.dataRef.children = res.item.map(item => {
              item.isLeaf = item.childsize === 0;
              item.scopedSlots = {title: 'customTreeNode'}
              return item;
            });
            this.userDeptTree = [...this.userDeptTree];
            resolve();
          }
        }).catch(err => {
          this.hideLoading();
        })
      });
    },
    selectDept(item) {
      let items;
      if(item.length>0){
        getUserdepListByCondition({userdepid:item[0]}).then(res=>{
          if(res&&res.returncode=='0') {
            items = res.item[0]
            if (items.levelid == 16 && items.monitorid != 0) {
              if (this.type == 'one') {
                if (items.equipmenttype == 0) {
                  playClients({monitornum: items.monitorid})
                  let that = this;
                  setTimeout(function () {
                    that.previewClients(items.userdepname,items.monitorid,0);
                  }, 200);
                } else {
                  this.previewDevs(items.userdepname, items.monitorid, 0)
                }
              } else if (this.type == 'four') {
                if (items.equipmenttype == 0) {
                  playClients({monitornum: items.monitorid})
                  let that = this;
                  setTimeout(function () {
                    that.previewClients(items.userdepname,items.monitorid,1);
                  }, 200);
                } else {
                  this.previewDevs(items.userdepname, items.monitorid, 1)
                }
              }
            }
          }
        })
      }
    },
    previewClients(devicename,monitorid,type){
      if(type==1){
        let children={}
        for(let i=0;i<4;i++){
          if(this.fourWindows[i].flag){
            continue
          }else{
            children={
              name:devicename,
              id:i,
              num:monitorid,
              src:"http://pmc-ulakedownstreamingserver1.u-lake.com:11583/live/" + monitorid + ".flv",
              flag:true,
            }
            this.fourWindows[i]=children
            if(i==0){
              this.name_0=devicename
            }else if(i==1){
              this.name_1=devicename
            }else if(i==2){
              this.name_2=devicename
            }else if(i==3){
              this.name_3=devicename
            }
            break
          }
        }
        let that=this;
        setTimeout(function () {
          that.previews(children.id, children.src);
        }, 200);
      }else if(type==2){
        let children={}
        for(let i=0;i<6;i++){
          if(this.nineWindows[i].flag){
            continue
          }else{
            children={
              name:devicename,
              id:i,
              num:monitorid,
              src:"http://pmc-ulakedownstreamingserver1.u-lake.com:11583/live/" + monitorid + ".flv",
              flag:true,
            }
            this.nineWindows[i]=children
            if(i==0){
              this.name_0=devicename
            }else if(i==1){
              this.name_1=devicename
            }else if(i==2){
              this.name_2=devicename
            }else if(i==3){
              this.name_3=devicename
            }else if(i==4){
              this.name_4=devicename
            }else if(i==5){
              this.name_5=devicename
            }
            break
          }
        }
        let that=this;
        setTimeout(function () {
          that.previews(children.id, children.src);
        }, 200);
      }else if(type==0){
        let children={}
        children={
          name:devicename,
          id:0,
          num:monitorid,
          src:"http://pmc-ulakedownstreamingserver1.u-lake.com:11583/live/" + monitorid + ".flv",
          flag:true,
        }
        this.oneWindows[0]=children
        this.name=devicename
        let that=this;
        setTimeout(function () {
          that.previews(children.id, children.src);
        }, 200);
      }
    },
    async previewDevs(devicename,monitorid,type){
      let params = {
        devicenum: monitorid,
        data: [
          {
            params: {
              methord: "PUT",
              url:"/video",
              body: {
                ssrc:monitorid+"-0",
                playtype:1,
                start_time:this.start_time,
                end_time:this.end_time,
              }
            }
          }
        ]
      }
      await setPlayerParams(params).then(res=>{
        if(res&&res.returncode=='0') {
          this.videoUrl=res.url
          if(this.videoUrl==""){
            this.$message.error("未获取到播放地址")
          }else{
            if(type==1){
              let children={}
              for(let i=0;i<4;i++){
                if(this.fourWindows[i].flag){
                  continue
                }else{
                  children={
                    name:devicename,
                    id:i,
                    num:monitorid,
                    src:this.videoUrl,
                    flag:true,
                  }
                  this.fourWindows[i]=children
                  if(i==0){
                    this.name_0=devicename
                  }else if(i==1){
                    this.name_1=devicename
                  }else if(i==2){
                    this.name_2=devicename
                  }else if(i==3){
                    this.name_3=devicename
                  }
                  break
                }
              }
              let that=this;
              setTimeout(function () {
                that.previews(children.id, children.src);
              }, 200);
            }else if(type==2){
              let children={}
              for(let i=0;i<6;i++){
                if(this.nineWindows[i].flag){
                  continue
                }else{
                  children={
                    name:devicename,
                    id:i,
                    num:monitorid,
                    src:this.videoUrl,
                    flag:true,
                  }
                  this.nineWindows[i]=children
                  if(i==0){
                    this.name_0=devicename
                  }else if(i==1){
                    this.name_1=devicename
                  }else if(i==2){
                    this.name_2=devicename
                  }else if(i==3){
                    this.name_3=devicename
                  }else if(i==4){
                    this.name_4=devicename
                  }else if(i==5){
                    this.name_5=devicename
                  }
                  break
                }
              }
              let that=this;
              setTimeout(function () {
                that.previews(children.id, children.src);
              }, 200);
            }else if(type==0){
              let children={}
              children={
                name:devicename,
                id:0,
                num:monitorid,
                src:this.videoUrl,
                flag:true,
              }
              this.oneWindows[0]=children
              this.name=devicename
              let that=this;
              setTimeout(function () {
                that.previews(children.id, children.src);
              }, 200);
            }
          }
        }
      })
    },
    previews(id,src) {
      let that =this
      if (flvjs.isSupported()) {
        if(this.type=='four'){
          if(id==0){
            this.videoElement0 = document.getElementById('video_0');
            this.flvPlayer0 = flvjs.createPlayer({
              type: 'flv',
              url: src,
              stashInitialSize: 128,// 减少首桢显示等待时长
            });
            this.flvPlayer0.attachMediaElement(this.videoElement0);
            this.flvPlayer0.load();
            this.flvPlayer0.play();
            this.flvPlayer0.on(flvjs.Events.ERROR, (errorType, errorDetail, errorInfo) => {});
          }else if(id==1){
            this.videoElement1 = document.getElementById('video_1');
            this.flvPlayer1 = flvjs.createPlayer({
              type: 'flv',
              url: src,
              stashInitialSize: 128,// 减少首桢显示等待时长
            });
            this.flvPlayer1.attachMediaElement(this.videoElement1);
            this.flvPlayer1.load();
            this.flvPlayer1.play();
            this.flvPlayer1.on(flvjs.Events.ERROR, (errorType, errorDetail, errorInfo) => {});
          }else if(id==2){
            this.videoElement2 = document.getElementById('video_2');
            this.flvPlayer2 = flvjs.createPlayer({
              type: 'flv',
              url: src,
              stashInitialSize: 128,// 减少首桢显示等待时长
            });
            this.flvPlayer2.attachMediaElement(this.videoElement2);
            this.flvPlayer2.load();
            this.flvPlayer2.play();
            this.flvPlayer2.on(flvjs.Events.ERROR, (errorType, errorDetail, errorInfo) => {
            });
          }else if(id==3){
            this.videoElement3 = document.getElementById('video_3');
            this.flvPlayer3 = flvjs.createPlayer({
              type: 'flv',
              url: src,
              stashInitialSize: 128,// 减少首桢显示等待时长
            });
            this.flvPlayer3.attachMediaElement(this.videoElement3);
            this.flvPlayer3.load();
            this.flvPlayer3.play();
            this.flvPlayer3.on(flvjs.Events.ERROR, (errorType, errorDetail, errorInfo) => {});
          }
        }else if(this.type=='nine'){
          if(id==0){
            this.videoElement0 = document.getElementById('video_0');
            this.flvPlayer0 = flvjs.createPlayer({
              type: 'flv',
              url: src,
              stashInitialSize: 128,// 减少首桢显示等待时长
            });
            this.flvPlayer0.attachMediaElement(this.videoElement0);
            this.flvPlayer0.load();
            this.flvPlayer0.play();
            this.flvPlayer0.on(flvjs.Events.ERROR, (errorType, errorDetail, errorInfo) => {});
          }else if(id==1){
            this.videoElement1 = document.getElementById('video_1');
            this.flvPlayer1 = flvjs.createPlayer({
              type: 'flv',
              url: src,
              stashInitialSize: 128,// 减少首桢显示等待时长
            });
            this.flvPlayer1.attachMediaElement(this.videoElement1);
            this.flvPlayer1.load();
            this.flvPlayer1.play();
            this.flvPlayer1.on(flvjs.Events.ERROR, (errorType, errorDetail, errorInfo) => {});
          }else if(id==2){
            this.videoElement2 = document.getElementById('video_2');
            this.flvPlayer2 = flvjs.createPlayer({
              type: 'flv',
              url: src,
              stashInitialSize: 128,// 减少首桢显示等待时长
            });
            this.flvPlayer2.attachMediaElement(this.videoElement2);
            this.flvPlayer2.load();
            this.flvPlayer2.play();
            this.flvPlayer2.on(flvjs.Events.ERROR, (errorType, errorDetail, errorInfo) => {});
          }else if(id==3){
            this.videoElement3 = document.getElementById('video_3');
            this.flvPlayer3 = flvjs.createPlayer({
              type: 'flv',
              url: src,
              stashInitialSize: 128,// 减少首桢显示等待时长
            });
            this.flvPlayer3.attachMediaElement(this.videoElement3);
            this.flvPlayer3.load();
            this.flvPlayer3.play();
            this.flvPlayer3.on(flvjs.Events.ERROR, (errorType, errorDetail, errorInfo) => {});
          }else if(id==4){
            this.videoElement4 = document.getElementById('video_4');
            this.flvPlayer4 = flvjs.createPlayer({
              type: 'flv',
              url: src,
              stashInitialSize: 128,// 减少首桢显示等待时长
            });
            this.flvPlayer4.attachMediaElement(this.videoElement4);
            this.flvPlayer4.load();
            this.flvPlayer4.play();
            this.flvPlayer4.on(flvjs.Events.ERROR, (errorType, errorDetail, errorInfo) => {});
          }else if(id==5){
            this.videoElement5 = document.getElementById('video_5');
            this.flvPlayer5 = flvjs.createPlayer({
              type: 'flv',
              url: src,
              stashInitialSize: 128,// 减少首桢显示等待时长
            });
            this.flvPlayer5.attachMediaElement(this.videoElement5);
            this.flvPlayer5.load();
            this.flvPlayer5.play();
            this.flvPlayer5.on(flvjs.Events.ERROR, (errorType, errorDetail, errorInfo) => {});
          }
        }else if(this.type=='one'){
          this.videoElement = document.getElementById('video');
          this.flvPlayer = flvjs.createPlayer({
            type: 'flv',
            url: src,
            stashInitialSize: 128,// 减少首桢显示等待时长
          });
          this.flvPlayer.attachMediaElement(this.videoElement);
          this.flvPlayer.load();
          this.flvPlayer.play();
          this.flvPlayer.on(flvjs.Events.ERROR, (errorType, errorDetail, errorInfo) => {});
        }
      }
    },
    getDeptTypes() {
      let params = {
        dictype: '10'
      };
      getDictByDicType(params).then(res => {
        if(res && res.returncode == '0') {
          if(this.userInfo.usertype == '0') {
            this.deptTypes = res.item;
          }else {
            this.deptTypes = res.item.filter(item => item.dickey == this.userInfo.usertype)
          }
        }
      })
    },
    getDeptTree(parentid='') {
      let params = {
        parentid
      };
      this.showLoading();
      getChildTreeListByParentId(params).then(res => {
        this.hideLoading();
        if(res && res.returncode == '0') {
          let array=res.item.filter(items=>{
            if(items.deptype==1){
              return items
            }
          });
          this.userDeptTree = array.map(item => {
            item.isLeaf = item.childsize === 0;
            item.scopedSlots = {title: 'customTreeNode'}
            return item;
          });
        }
      }).catch(err => {
        this.hideLoading();
      })
    },
    initPlayer(){
      this.lists.forEach((item, index) => {
        if (flvjs.isSupported()) {
          this.videoElement = document.getElementById('video'+item.id);
          this.flvPlayer = flvjs.createPlayer({
            type: 'flv',
            url: item.src,
            stashInitialSize: 128,// 减少首桢显示等待时长
          });
          this.flvPlayer.attachMediaElement(this.videoElement);
          this.flvPlayer.load();
          this.flvPlayer.play();
        }
      })
    },
    remoteControl(item){
      this.modalVisible=true
      getUserdepListByCondition({userdepname:item}).then(res=> {
        if (res && res.returncode == '0') {
          this.formDatas=res.item[0]
        }
      })
    },
    close(e){
      if(e==0){
        if(this.flvPlayer0){
          this.flvPlayer0.pause()
          this.flvPlayer0.unload()
          this.flvPlayer0.detachMediaElement()
          this.flvPlayer0.destroy()
          this.flvPlayer0 = null
          this.fourWindows[e]={
            name:'',
            id:'',
            src:'',
            flag:false,
          }
          this.nineWindows[e]={
            name:'',
            id:'',
            src:'',
            flag:false,
          }
        }
        this.name_0=""
      }else if(e==1){
        if(this.flvPlayer1){
          this.flvPlayer1.pause()
          this.flvPlayer1.unload()
          this.flvPlayer1.detachMediaElement()
          this.flvPlayer1.destroy()
          this.flvPlayer1 = null
          this.fourWindows[e]={
            name:'',
            id:'',
            src:'',
            flag:false,
          }
          this.nineWindows[e]={
            name:'',
            id:'',
            src:'',
            flag:false,
          }
        }
        this.name_1=""
      }else if(e==2){
        if(this.flvPlayer2){
          this.flvPlayer2.pause()
          this.flvPlayer2.unload()
          this.flvPlayer2.detachMediaElement()
          this.flvPlayer2.destroy()
          this.flvPlayer2 = null
          this.fourWindows[e]={
            name:'',
            id:'',
            src:'',
            flag:false,
          }
          this.nineWindows[e]={
            name:'',
            id:'',
            src:'',
            flag:false,
          }
        }
        this.name_2=""
      }else if(e==3){
        if(this.flvPlayer3){
          this.flvPlayer3.pause()
          this.flvPlayer3.unload()
          this.flvPlayer3.detachMediaElement()
          this.flvPlayer3.destroy()
          this.flvPlayer3 = null
          this.fourWindows[e]={
            name:'',
            id:'',
            src:'',
            flag:false,
          }
          this.nineWindows[e]={
            name:'',
            id:'',
            src:'',
            flag:false,
          }
        }
        this.name_3=""
      } else if(e==4){
        if(this.flvPlayer4){
          this.flvPlayer4.pause()
          this.flvPlayer4.unload()
          this.flvPlayer4.detachMediaElement()
          this.flvPlayer4.destroy()
          this.flvPlayer4 = null
          this.nineWindows[e]={
            name:'',
            id:'',
            src:'',
            flag:false,
          }
        }
        this.name_4=""
      }else if(e==5){
        if(this.flvPlayer5){
          this.flvPlayer5.pause()
          this.flvPlayer5.unload()
          this.flvPlayer5.detachMediaElement()
          this.flvPlayer5.destroy()
          this.flvPlayer5 = null
          this.nineWindows[e]={
            name:'',
            id:'',
            src:'',
            flag:false,
          }
        }
        this.name_5=""
      }
    },
    closeOne(e){
      if(this.flvPlayer){
        this.flvPlayer.pause()
        this.flvPlayer.unload()
        this.flvPlayer.detachMediaElement()
        this.flvPlayer.destroy()
        this.flvPlayer = null
        this.oneWindows[e]={
          name:'',
          id:'',
          src:'',
          flag:false,
        }
      }
      this.name=""
    },
    search() {
      this.tableLoading = true;
      let params = {
        userdepname:this.devicename,
        levelid:16
      };
      getUserdepListByCondition(params).then(res => {
        this.tableLoading = false;
        if (res && res.returncode == '0') {
          this.show=false;
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    returnPage(){
      this.show=true
    },
    rowClick(record, index) {
      let that=this
      return {
        on: {
          dblclick: () => {
            playClients({monitornum:record.monitorid})
            if(that.type=='one'){
              if(record.monitorid!=0){
                if (record.equipmenttype == 0) {
                  playClients({monitornum: record.monitorid})
                  let that = this;
                  setTimeout(function () {
                    that.previewClients(record.userdepname,record.monitorid,0);
                  }, 200);
                }else{
                  this.previewDevs(record.userdepname,record.monitorid,0)
                }
              }
            }else if(that.type=='four'){
              if(record.monitorid!=0){
                if (record.equipmenttype == 0) {
                  setTimeout(function () {
                    that.previewClients(record.userdepname,record.monitorid,1);
                  }, 200);
                }else{
                  this.previewDevs(record.userdepname,record.monitorid,1)
                }
              }
            }
          }
        }
      }
    },
    initialize(){
      this.reloadflag=false
      this.currentnum=0
      this.isturn=false
      this.oneWindows=[
        {
          name:'',
          num:'',
          id:'',
          src:'',
          flag:false,
        },
      ]
      this.fourWindows=[
        {
          name:'',
          num:'',
          id:'',
          src:'',
          flag:false,
        },
        {
          name:'',
          num:'',
          id:'',
          src:'',
          flag:false,
        },
        {
          name:'',
          num:'',
          id:'',
          src:'',
          flag:false,
        },
        {
          name:'',
          num:'',
          id:'',
          src:'',
          flag:false,
        }
      ]
      this.nineWindows=[
        {
          name:'',
          num:'',
          id:'',
          src:'',
          flag:false,
        },
        {
          name:'',
          num:'',
          id:'',
          src:'',
          flag:false,
        },
        {
          name:'',
          num:'',
          id:'',
          src:'',
          flag:false,
        },
        {
          name:'',
          num:'',
          id:'',
          src:'',
          flag:false,
        },
        {
          name:'',
          num:'',
          id:'',
          src:'',
          flag:false,
        },
        {
          name:'',
          num:'',
          id:'',
          src:'',
          flag:false,
        }
      ]
      this.name_0=''
      this.name_1=''
      this.name_2=''
      this.name_3=''
      this.name_4=''
      this.name_5=''
      this.flvPlayer0=null
      this.flvPlayer1=null
      this.flvPlayer2=null
      this.flvPlayer3=null
      this.flvPlayer4=null
      this.flvPlayer5=null
      this.videoElement0=null
      this.videoElement1=null
      this.videoElement2=null
      this.videoElement3=null
      this.videoElement4=null
      this.videoElement5=null
      this.closeOne(0)
      for(let i=0;i<6;i++){
        this.close(i)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.left-page {
  float: left;
  display: flex;
  height: 91%;
  width: 18%;
  margin-top: 10px;
  margin-left: 10px;
  padding: 0 20px;
  background-color: white;
  padding: 10px 14px;
  border-radius: 2px;
}
.right-page {
  float: right;
  display: flex;
  height: 91%;
  width: 80%;
  margin-top: 10px;
  margin-right: 10px;
  padding: 0 20px;
  background-color: black;
  padding: 10px 14px;
  border-radius: 2px;
}
.right-left-page{
  width: 100%;
  height: 100%;
  border:2px solid white
}
.right-right-page{
  width: 50%;
  height: 100%;
  border:2px solid white
}
.video{
  display:flex;
  flex-wrap:wrap;
  overflow: hidden;
  height: 100%;
  width: 100%;
}
</style>